import React from "react"
import { Layout } from "../components"
import * as styles from "../styles/gallery.module.scss"
import Container from "../components/Container"
import { StaticImage } from "gatsby-plugin-image"

const gallery = () => {
  return (
    <Layout>
      <Container>
        <div className={styles.grid_wrapper}>
          <div>
            <StaticImage src="../images/gallery/1.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/2.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/3.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/4.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/5.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/6.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/7.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/8.jpg" alt="gallery image" />
          </div>
          {/* <div>
            <StaticImage src="../images/gallery/9.jpg" alt="gallery image" />
          </div> */}
          <div>
            <StaticImage src="../images/gallery/10.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/11.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/12.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/13.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/14.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/15.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/16.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/17.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/18.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/19.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/20.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/21.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/22.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/23.jpg" alt="gallery image" />
          </div>
          {/* <div>
            <StaticImage src="../images/gallery/24.jpg" alt="gallery image" />
          </div> */}
          <div>
            <StaticImage src="../images/gallery/25.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/26.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/27.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/28.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/29.jpg" alt="gallery image" />
          </div>
          <div>
            <StaticImage src="../images/gallery/30.jpg" alt="gallery image" />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default gallery
